import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/mnt/d/ShapeGanicGatsby/src/components/blog/layout.js";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import Disclosure from "../../../components/blog/legal/affiliateMarketing.de.mdx";
import BlogOverview from "../../../components/blog/overview/blogOverview.de.mdx";
import Layout from "../../../components/blog/layout.de";
export const query = graphql`
  {
    mdx(slug: { eq: "wie-nehme-ich-creatin-richtig-ein/index.de" }) {
      frontmatter {
        main_image {
          publicURL
        }
        image_introduction {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
        image_introduction_alt
        image_introduction_desc
        image_introduction_credit_text
        image_introduction_credit_link
        image_first {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
        image_first_alt
        image_first_desc
        image_first_credit_text
        image_first_credit_link
        image_second {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
        image_second_alt
        image_second_desc
        image_second_credit_text
        image_second_credit_link
        date(formatString: "D. MMMM YYYY", locale: "de-DE")
      }
      fields {
        readingTime {
          minutes
        }
      }
    }
  }
`;
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const SEO = makeShortcode("SEO");
const Title = makeShortcode("Title");
const Image = makeShortcode("Image");
const AffiliateLink = makeShortcode("AffiliateLink");
const Link = makeShortcode("Link");
const NavLinkGlobal = makeShortcode("NavLinkGlobal");
const AppPromotion = makeShortcode("AppPromotion");
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEO lang={props.pageContext.frontmatter.lang} description={props.pageContext.frontmatter.summary} title={props.pageContext.frontmatter.title} keywords={props.pageContext.frontmatter.keywords} imageUrl={props.data.mdx.frontmatter.main_image.publicURL} mdxType="SEO" />
    <Title title={props.pageContext.frontmatter.title} date={props.data.mdx.frontmatter.date} readingTime={props.data.mdx.fields.readingTime.minutes} mdxType="Title" />
    <p>
  <b>{props.pageContext.frontmatter.summary}</b> Creatin ist das wohl älteste
  und am besten untersuchte Nahrungsergänzungsmittel auf dem Supplementmarkt.
  Dennoch ranken sich viele irreführende Mythen um das weiße Pulverchen.
  Gleichzeitig tümmeln sich erschreckend viele Fehlinformationen im Internet,
  mit teilweise gefährlichen Empfehlungen. In diesem Artikel wirst du alles über
  Creatin lernen, damit du in Zukunft bestens informiert bist!
    </p>
    <Image image={getImage(props.data.mdx.frontmatter.image_introduction)} imageAlt={props.data.mdx.frontmatter.image_introduction_alt} imageDesc={props.data.mdx.frontmatter.image_introduction_desc} imageAuthor={props.data.mdx.frontmatter.image_introduction_credit_text} imageLink={props.data.mdx.frontmatter.image_introduction_credit_link} mdxType="Image" />
    <h2>{`Du nimmst kein Creatin? Dann verzichtest Du auf diese Vorteile...`}</h2>
    <p>
  Deine sportlichen Ziele sind <b>Kraft</b> oder <b>Schnelligkeit</b>? Du
  betreibst eine Sportart bei der <b>Fitness-</b>, <b>Sprint-</b>, <b>Lauf-</b>{" "}
  oder <b>Sprungtrainings</b> integraler Bestandteil sind? Du möchtest{" "}
  <b>Muskeln aufbauen</b> und dir ist es wichtig alles aus deinem Training zu
  holen? Dann solltest du unbedingt weiterlesen, vor allem, wenn du seit
  längerem mit einem Trainingsplateau zu kämpfen hast. Das
  Nahrungsergänzungsmittel{" "}
  <AffiliateLink url={props.pageContext.frontmatter.product_creatin_url} mdxType="AffiliateLink">
    {props.pageContext.frontmatter.product_creatin_text}
  </AffiliateLink>{" "}
  ist genau das Richtige für dich!
    </p>
    <p>
  In zahlreichen über Jahrzehnte durchgefühten Studien konnte die
  leistungssteigernde, anabole sowie antikatabole Wirkung von Creatin eindeutig
  bewiesen werden. Unter dem Begriff "anabol" sind muskelaufbauende
  Stoffwechselprozesse zu verstehen, wohingegen "antikatabol" die Unterdrückung
  von muskelabbauenden Prozessen beschreibt. Insbesondere bei anaeroben
  Aktivitäten als auch Intervallbelastungen wurden signifikante
  Leistungssteigerungen nachgewiesen. Auch gilt dies für klassische
  Bodybuilding- und Fitnessübungen wie <b>Kniebeugen</b>, <b>Kreuzheben</b>,{" "}
  <b>Bankdrücken</b>, <b>Beinpressen</b>, <b>Rudern</b> und dergleichen. Hier
  konnten Kraftzunahmen im zweistelligen Prozentbereich gemessen werden.
    </p>
    <h3>{`Creatin ist eine Energiequelle und so wirkt sie...`}</h3>
    <p>
  Selbstverständlich sollst du nicht einfach alles abnicken, was in diesem
  Artikel behauptet wird. Eine kritische Auseinandersetzung ist fundamental für
  eine gesunde Ernährung und den vernünftigen Einsatz von
  Nahrungsergänzungsmitteln. Deshalb wirst du jetzt erfahren, was Creatin
  eigentlich ist, woraus es sich zusammensetzt und wofür es in deinem Körper
  eingesetzt wird.
    </p>
    <Image image={getImage(props.data.mdx.frontmatter.image_first)} imageAlt={props.data.mdx.frontmatter.image_first_alt} imageDesc={props.data.mdx.frontmatter.image_first_desc} imageAuthor={props.data.mdx.frontmatter.image_first_credit_text} imageLink={props.data.mdx.frontmatter.image_first_credit_link} mdxType="Image" />
    <p>
  Die Energieform unserer Zellen nennt sich ATP, oder ausgeschrieben{" "}
  <b>Adenosintriphosphat</b>. Die Rolle des Creatins ist es, verbrauchtes ATP
  schnell wiederherzustellen, weshalb Creatin auch als schnell verfügbare
  Energiequelle bezeichnet wird. Bei Belastungen bis zu etwa 10 Sekunden, kann
  Creatin herangezogen werden, um verbrauchtes ATP wiederherzustellen, wodurch
  Muskelkontraktionen überhaupt möglich sind. Je mehr Creatin wir also in der
  Muskelzelle haben, desto länger sind unsere Muskeln in der Lage ein Maximum an
  Leistung zu vollbringen.
    </p>
    <p>
  Bei der Muskelkontraktion wird zur Energiegewinnung eine Phosphatgruppe des
  Adenosintriphosphats abgespalten, sodass anschließend Adenosindiphosphat (ADP)
  übrig bleibt, ein Molekül mit nur zwei Phosphatgruppen. Um Adenosintriphosphat
  resynthetisieren zu können, also dafür zu sorgen, dass wieder drei
  Phosphatgruppen vorliegen, wird Kreatinphosphat herangezogen. Dieses spaltet
  seine Phosphatgruppe ab, welche dem ADP nun zur Resynthese von ATP zur
  Verfügung steht. ATP ist jetzt wieder zur Energiegewinnung für weitere
  Muskelkontraktionen vorhanden.
    </p>
    <p>
  Creatin sorgt also dafür, dass du bei Bewegungen mit hohem bis maximalem
  Kraftaufwand mehr Leistung erbringen kannst. Nehmen wir die Übung Kniebeugen
  als Beispiel und stellen uns vor, dass du mit einem bestimmten Gewicht nie
  mehr als 3 Wiederholungen schaffst. Diesen Zustand bezeichnen wir als Plateau,
  oder anders formuliert als zeitliche Sackgasse, die es mit geschicktem
  Vorgehen zu überwinden gilt. Nachdem deine Muskulatur nach einigen Wochen der
  Einnahme mit{" "}
  <AffiliateLink url={props.pageContext.frontmatter.product_creatin_url} mdxType="AffiliateLink">
    {props.pageContext.frontmatter.product_creatin_text}
  </AffiliateLink>{" "}
  angereichert ist, wirst Du sehr wahrscheinlich die 4., 5. oder gar 6.
  Wiederholung schaffen. Durch die zusätzlichen Wiederholungen, welche du vorher
  nicht geschafft hast, setzt Du einen Muskelwachstumsreiz, der so vorher nicht
  möglich war.
    </p>
    <p>
  Letztlich stellt Creatin bzw. Creatinphosphat nur eine von 3 Möglichkeiten der
  ATP-Resynthese dar. Die anderen beiden sogenannten Energiesubstrate sind{" "}
  <b>Kohlenhydrate</b>, bzw. das in der Muskulatur gespeicherte Glykogen, und{" "}
  <b>Fett</b>. Hierbei ist jedoch Creatin die effektivste und effizienteste
  Energiequelle, insbesondere wenn es um kurze, explosive und hochintensive
  Belastungen geht.
    </p>
    <h2>{`Die körpereigene Creatinsynthese ist ausreichend! Oder etwa nicht?`}</h2>
    <p>
  Ja, Creatin ist ein körpereigener Stoff bzw. ein chemisches Element, welches
  der Körper selbst synthetisieren kann. Man spricht in diesem Zusammenhang in
  der Fachliteratur von einer <b>endogenen</b> Creatinsynthese. Im Gegensatz
  dazu sagt <b>exogen</b> aus, dass die Bereitstellung von außen, wie z.B. durch
  die Nahrung, erfolgt. Die Behauptung ist daher naheliegend, dass man überhaupt
  kein Creatin von außen zuführen muss. Doch ist das wirklich so? Dieser Frage
  werden wir jetzt nachgehen.
    </p>
    <h3>{`Zur Creatinsynthese sind Glycin, Arginin und Methionin erforderlich!`}</h3>
    <p>
  Creatin setzt sich aus den 3 Aminosäuren <b>Glycin</b>, <b>Arginin</b> und{" "}
  <b>Methionin</b> zusammen. In diesem Kontext sind die Begriffsdefinitionen <b>essentiell</b>, <b>
    semi-essentiell
  </b> und <b>nicht essentiell</b> erforderlich. Essentiell bedeutet, dass der jeweilige
  Stoff nicht endogen synthetisiert werden kann, er muss also über die Nahrung aufgenommen
  werden. Nicht essentiell wiederum sagt aus, dass dein Körper das Element selbst
  synthetisieren kann. Der in Fachkreisen noch nicht so geläufige Begriff <b>
    semi-essentiell
  </b> beschreibt einen etwas komplexeren Sachverhalt. Semi-essentiell besagt, dass
  dein Organismus in einer gewöhnlichen Stoffwechselsituation in der Lage ist, den
  entsprechenden Stoff selbst in ausreichender Menge herzustellen. Bei einer veränderten
  Stoffwechsellage, wie z.B. beim Muskelaufbau, starken Belastungen oder Stress,
  kann es dazu kommen, dass die endogene Synthese nicht ausreichend ist. Auch können
  verschiedene Stoffwechselprozesse in Bezug auf semi-essentielle Stoffe miteinander
  konkurrieren. In einer solchen Situation wird der semi-essentielle Stoff essentiell
  und muss folglich auch über die Nahrung zugeführt werden.
    </p>
    <p>
  Diese Begriffsdefinitionen wenden wir nun auf <b>Creatin</b> an. Creatin
  selbst ist aus wissenschaftlicher Sicht als <b>semi-essentiell</b>{" "}
  klassifiziert. Gleichzeitig sind die zur Synthese von Creatin erforderlichen
  Aminosäuren teilweise auch (semi-)essentiell. Während <b>Glycin</b>{" "}
  <b>nicht essentiell</b> ist, so ist <b>Arginin</b>{" "}
  <b>semi-essentiell</b> und <b>Methionin</b> sogar <b>essentiell</b>. Die endogene
  Bereitstellung von Creatin stellt eine sogenannte <b>metabolische Last</b> dar.
  Das bedeutet, dass es für deinen Körper mit einem relativ hohen Aufwand verbunden
  ist, Creatin zu synthetisieren. Auch müssen die zur Creatinsynthese erforderlichen
  Aminosäuren entweder selbst hergestellt (nicht-essentiell), oder über die Nahrung
  aufgenommen werden (essentiell oder semi-essentiell). Beispielsweise muss die semi-essentielle
  Aminosäure Arginin entweder direkt, oder zumindest die zur Herstellung erforderlichen
  Aminosäuren, über die Nahrung aufgenommen werden.
    </p>
    <p>
  Neben der Creatinsynthese gibt es andere Stoffwechselprozesse wie z.B. den
  Muskelaufbau, die um die semi-essentiellen Aminosäuren konkurrieren. Als
  Sportler hast du also grundsätzlich einen Mehrbedarf an Creatin. Zum einen für
  Stoffwechselprozesse für die Creatin zwingend erforderlich ist und zum anderen
  für sportliche Aktivitäten.
    </p>
    <p>
  Zusammenfassend solltest du dir also merken, dass Creatin ein körpereigener
  Stoff ist, der endogen synthetisiert werden kann. Die Synthesemenge deckt
  lediglich nur das absolut notwendige Minimum ab.{" "}
  <b>
    Es ist also sehr empfehlenswert Creatin als Nahrungsergänzungsmittel
    unterstützend einzunehmen, insbesondere wenn du sportlich aktiv bist!
  </b>
    </p>
    <h2>{`Das musst du über einen Creatinmangel wissen...`}</h2>
    <p>
  Im letzten Abschnitt hast du gelernt, dass es durchaus vernünftig ist,{" "}
  <AffiliateLink url={props.pageContext.frontmatter.product_creatin_url} mdxType="AffiliateLink">
    {props.pageContext.frontmatter.product_creatin_text}
  </AffiliateLink>{" "}
  als Nahrungsergänzungsmittel zusätzlich zu einer ausgewogenen Ernährung
  einzunehmen. Jetzt wollen wir uns weiter mit der Materie beschäftigen und die
  Frage beantworten, ob es überhaupt so etwas wie einen Creatinmangel gibt und
  wenn "ja", wodurch dieser zustande kommt. Teilweise wird Creatin in der
  Fachliteratur weiterhin als nicht-essentiell eingestuft. Diese Klassifizierung
  werden wir ebenfalls kritisch hinterfragen.
    </p>
    <p>
  <b>Kurz für dich zur Wiederholung:</b> Creatin ist ein körpereigenes Element,
  welches in einer normalen Stoffwechsellage endogen in ausreichender Menge
  synthetisiert wird. Es ist an dieser Stelle naheliegend Creatin als
  nicht-essentiell einzustufen und gleichzeitig festzuhalten, dass ein Mangel
  ausgeschlossen ist. Doch ist diese Aussage nur richtig, wenn unser Stoffwechel
  sich nicht in einer Ausnahmesituation, wie einer anabolen bzw.
  muskelaufbauenden Stoffwechselsituation, befindet. Dieser Umstand führt dazu,
  dass du einen signifikanten Mehrbedarf an Creatin hast.
    </p>
    <p>
  Ein Großteil des Kreatins im Körper wird in Form von Kreatinphosphat in der
  Muskulatur eingelagert. Dadurch erhöht sich die insgesamt erforderliche Menge
  an Creatin, die täglich endogen synthetisiert oder exogen z.B. durch Nahrung
  oder Supplements zugeführt werden muss. Nach wie vor bleibt die Frage
  bestehen, ob in einer solchen Situation die endogene Creatinsynthese
  ausreichend ist und wir dadurch Creatin weiterhin als nicht essentiell
  einstufen können.
    </p>
    <p>
  Wie du oben bereits erfahren hast, wird Creatin aus der nicht essentiellen
  Aminosäure Glycin, der semi-essentiellen Aminosäure Arginin und der
  essentiellen Aminosäure Methionin synthetisiert. Stehen diese Aminosäuren
  folglich nicht in ausreichender Menge zur Verfügung, so kommt die endogene
  Creatinsynthese zum Erliegen. Um die endogene Creatinsynthese in allen
  Stoffwechselsituationen grundsätzlich sicherzustellen, müsstest du zumindest
  die (semi-)essentiellen Aminosäuren in ausreichender Menge über die Nahrung
  zuführen. Dieser Umstand stellt sich jedoch in der Praxis als große
  Herausforderung dar.{" "}
  <b>
    Wir können diese Situation definitiv als Creatin-Mangel definieren und
    dementsprechend Creatin als essentiell einstufen! Um einen möglichen Mangel
    zu verhindern muss Creatin über die Nahrung oder Supplements zugeführt
    werden.
  </b>
    </p>
    <p>
  Diese Problematik gilt für uns alle im Allgemeinen, aber für Vegetarier und
  Veganer im Speziellen. Pflanzliche Ernährung enthält höchstens in Spuren
  Creatin, aber auch die zur endogenen Creatinsynthese erforderlichen
  Aminosäuren können nur unzureichend über eine pflanzliche Ernährung
  sichergestellt werden. Wie oben bereits erwähnt, wird die{" "}
  <b>endogene Creatinsynthese</b> als <b>metabolische Last</b> bezeichnet; die
  Beanspruchung unseres Organismus bzw. unseres Stoffwechsels ist hierbei nicht
  unerheblich! Aktuelle Studien legen nahe, dass ohne exogene Kreatinzufuhr
  bestimmte Stoffwechselprozesse verlangsamt oder gehemmt werden könnten. Zu
  diesen zählen durchaus anabole, also muskelaufbauende, Stoffwechselprozesse.
    </p>
    <p>
  Die endogene, also körpereigene, Creatinsynthese beläuft sich auf etwa 1 bis 2
  g täglich. Je nach körperlicher Belastung ist eine tägliche Creatinmenge von 2
  bis 3 g erforderlich, die über die Nahrung aufgenommen werden muss.
  Diesbezüglich liegen aber auch wissenschaftliche Studien vor, welche sogar
  Mengen von 5 bis 6 g ermittelt haben.
    </p>
    <p>
  <b>
    Als Fazit solltest du dir also merken, dass eine exogene, also von außen
    stammende, Kreatinzufuhr äußerst vorteilhaft sein kann. In diesem Sinne
    solltest du auch über eine mögliche Creatinsupplementation nachdenken, um
    sicherzustellen, dass es bei dir zu keinem Creatin-Mangel kommen kann.
  </b>
    </p>
    <h3>{`Du bist Veganer oder Vegetarier? Diese Wirkung erwartet dich...`}</h3>
    <p>
  Du solltest dich auch der Tatsache bewusst sein, dass dein Körper jeden Tag
  auch ohne sportliche Betätigung{" "}
  <AffiliateLink url={props.pageContext.frontmatter.product_creatin_url} mdxType="AffiliateLink">
    {props.pageContext.frontmatter.product_creatin_text}
  </AffiliateLink>{" "}
  benötigt. Das liegt daran, dass bei der Synthese von ATP dein Körper den
  anaeroben Weg wählt und hierbei auf das im Organismus gespeicherte Creatin
  zurückgreift. Gleichzeitig wird aber nur das absolut erforderliche Minimum
  ausgeglichen. Deine Creatinspeicher sind hierbei aber keineswegs gefüllt oder
  gar gesättigt. Das ist auch der Grund dafür, wieso Sportler im Allgemeinen und{" "}
  <b>Veganer und Vegetarier</b> im Speziellen{" "}
  <b>von einer Creatineinnahme profitieren</b>. Über eine erhöhte exogene Aufnahme
  von Creatin, also über die Nahrung oder Nahrungsergänzungsmittel, kann die Menge
  des in der Muskulatur gespeicherten Kreatinphosphats um etwa 20 bis 30 % erhöht
  werden. Bei <b>Veganern und Vegetariern</b> ist sogar ein <b>
    Auffüllpotential von bis zu 40 %
  </b> möglich.
    </p>
    <p>
  Dieses enorme Auffüllpotential bei Veganern und Vegetariern ist damit
  begründet, dass pflanzliche Nahrungsmittel praktisch kein elmentares Creatin
  enthalten. Die zur endogenen Synthese von Creatin erforderlichen Aminosäuren
  sind zudem häufig bei einer veganen Ernährungsweise nur in ungenügender Menge
  enthalten. Studien konnten hierbei zeigen, dass Vegetarier und Veganer einen
  Creatinspiegel haben, der etwa <b>10 bis 20 % unter</b> dem Wert liegt, der
  bei einer Mischkost gemessen wurde. Einen Geschlechterunterschied gibt es
  wiederum nicht. Pro Kilogramm Magermasse weisen Frauen und Männer identische
  Creatinwerte im Muskel auf.
    </p>
    <p>
  <b>Fazit:</b> Als Veganer oder Vegetarier profitierst du enorm von einer
  Creatinsupplementation! Wenn man zudem bedenkt, dass{" "}
  <AffiliateLink url={props.pageContext.frontmatter.product_creatin_url} mdxType="AffiliateLink">
    {props.pageContext.frontmatter.product_creatin_text}
  </AffiliateLink>{" "}
  äußerst günstig, effektiv und gesundheitsfördernd ist, solltest du dir diese
  Vorteile nicht entgehen lassen!
    </p>
    <h2>{`Du kannst Creatin über die Nahrung aufnehmen, wenn Du das beachtest...`}</h2>
    <p>
  Wenn du es dir auf die Fahne geschrieben hast, deinen Creatinbedarf partout
  über die Nahrung zu decken, dann solltest du unbedingt weiterlesen. Jetzt
  erfährst du, welche Nahrungsmittel eine hohe Creatinmenge aufweisen und auch
  welche Lebensmittel die zur Creatin erforderlichen Aminosäuren enthalten.
    </p>
    <p>
  <Link url={props.pageContext.frontmatter.creatin_wikipedia_url} mdxType="Link">
    {props.pageContext.frontmatter.creatin_wikipedia_text}
  </Link>{" "}
  in der Nahrung findet sich in nennenswerten Mengen ausschließlich in Fleisch,
  wozu auch Fisch zu zählen ist. Empirisch ermittelt liefert eine
  nichtvegetarische Mischkost etwa 1 g Creatin pro Tag. Fällt dein Fleischkonsum
  überdurchschnittlich hoch aus, sind folglich höhere exogene Creatinmengen
  naheliegend. <b>Rindfleisch</b> als auch <b>Lachs</b> enthalten beispielsweise
  jeweils <b>4,5 g Creatin</b> pro Kilogramm. Folglich müsstest du über 1 kg
  Rindfleisch oder Lachs pro Tag verzehren, um auf eine tägliche Creatinmenge
  von etwa 5 g zu kommen. Dass dieser Ansatz langfristig nicht realisierbar ist
  sollte naheliegend sein.
    </p>
    <p>
  Solltest du dennoch versuchen, deinen Creatinbedarf, der je nach sportlicher
  Aktivität deutlich erhöht sein kann, grundsätzlich über die Nahrung bzw. die{" "}
  <b>endogene Synthese</b> zu decken, so solltest du auf Folgendes achten.
  Fangen wir hierbei mit der zur Creatinsynthese erforderlichen Aminosäure{" "}
  <b>Glycin</b> an. Die folgenden Nahrungsmittel, sortiert in absteigender
  Reihenfolge, enthalten jeweils eine hohe Menge an <b>Glycin</b>:{" "}
  <b>
    Gelantinepulver (ungesüßt), Kürbiskerne, Sojabohnen (getrocknet), Erbsen
    (getrocknet), Schweinefleisch, Hähnchenbrustfilet, Lachs, Walnüsse,
    Weizen-Vollkornmehl, Hühnerei, Reis (ungeschält), Mais-Vollkornmehl und
    Kuhmilch (3,7 % Fett)
  </b>
  .
    </p>
    <p>
  Von hohem zu geringem Gehalt sortiert, hast Du im Folgenden einen Überblick
  darüber, auf welche Nahrungsmittel du achten solltest, um ausreichende Mengen
  an <b>Arginin</b> über die Ernährung aufzunehmen:{" "}
  <b>
    Kürbiskerne, Erdnüsse (geröstet), Pinienkerne, Walnüsse, Erbsen
    (getrocknet), Hähnchenbrustfilet, Schweinefleisch, Lachs, Buchweizenkörner,
    Hühnerei, Weizen-Vollkornmehl, Reis (ungeschält), Mais-Vollkornmehl und
    Kuhmilch (3,7 % Fett)
  </b>
  .
    </p>
    <p>
  Abschließend stellt die folgende Aufzählung eine Liste von Nahrungsmitteln
  dar, welche einen hohen Gehalt an <b>Methionin</b> aufweisen. Wieder wurden
  die Nahrungsmittel von hohem zu niedrigem Gehalt der entsprechenden Aminosäure
  sortiert:{" "}
  <b>
    Paranüsse, Lachs, Sesamkörner, Rindfleisch, Hähnchenbrustfilet, Sojabohnen
    (getrocknet), Hühnerei, Erbsen (getrocknet), Walnüsse, Weizen-Vollkornmehl,
    Reis (ungeschält), Buchweizen-Mehl, Mais-Vollkornmehl, Kuhmilch (3,7 % Fett)
  </b>
  .
    </p>
    <p>
  Du hast also zwei Möglichkeiten deinen Creatinbedarf über die Nahrung zu
  decken: <b>a)</b> entweder über Nahrungsmittel die elementares Creatin liefern
  oder <b>b)</b> indem du Nahrungsmittel zu dir nimmst, welche die zur endogenen
  Synthese erforderlichen Aminosäuren enthalten.{" "}
  <b>
    Die effektivste und einfachstes Lösung, und zudem die mit Abstand
    günstigste, ist die Alternative c): die Einnahme von Creatin Monohydrat als
    Supplement!
  </b>
    </p>
    <h2>{`So solltest du Creatin auf keinen Fall einnehmen!`}</h2>
    <p>
  In den Anfängen der Creatinsupplementation wurde ein Einnahmeschema empfohlen,
  welches aus einer sogenannten Lade- und Erhaltungsphase bestand. Man
  bezeichnet dieses Einnahmeschema als <b>Creatin-Kur</b>. Die <b>Ladephase</b>{" "}
  hat eine Dauer von etwa 5 Tagen, bei der man 4 mal täglich 5 g Creatin zu sich
  nimmt, also insgesamt 20 g Creatin pro Tag. Im Anschluss an die Ladephase
  folgt die <b>Erhaltungsphase</b>, bei der die täglich eingenommene
  Creatindosis etwa 5 bis 10 g beträgt. Die Erhaltungsphase stellt hierbei aber
  keine Dauereinnahme dar, wie es nach aktueller Studienlage am sinnvollsten
  wäre. Sie ist zeitlich begrenzt und an sie schließt sich eine{" "}
  <b>Einnahmepause</b> von etwa vier Wochen an.
    </p>
    <p>
  Verglichen mit einer geringdosierten Dauereinnahme, wird bei einer Ladephase
  der maximale Creatinspiegel schneller erreicht, doch handelt es sich hierbei
  nur um wenige Tage. Berücksichtigt man jedoch, dass Creatin grundsätzlich über
  einen langen Zeitraum eingenommen werden muss, so relativiert sich dieser
  vermeintliche Vorteil sehr stark. Gleichzeitig konnte in einer
  wissenschaftlichen Studie festgestellt werden, dass die Kreatinspeicher der
  Muskulatur bei der Einnahme von lediglich 3 g am Tag nach 28 Tagen bereits
  maximal gefüllt sind.
    </p>
    <p>
  Dieses mittlerweile überholte und nicht empfehlenswerte Einnahmeschema hat
  gleich <b>mehrere Nachteile</b>. Erstens steigt die Wahrscheinlichkeit, dass
  ein großer Teil des Creatins während der Ladephase teilweise ungenutzt als
  Creatinin ausgeschieden wird. Gleichermaßen kann das hierbei entstandene
  Creatinin bei einer so hohen Creatinmenge unter Umständen zu einer negativen
  Beeinflussung der Nieren führen. Zweitens werden die Kreatin-abbauenden
  Prozesse grundsätzlich erhöht, um das überschüssige Creatin abzubauen und
  auszuscheiden. <b>Diesen Stoffwechselzustand gilt es zu vermeiden</b>, da er
  der eigentlichen Zielsetzung entgegenwirkt. Drittens wird dadurch die
  endogene, also körpereigene, Creatinsynthese stark zurückgefahren oder
  temporär ganz eingestellt. Schließlich sind bei einer hochdosierten Einnahme
  von Creatin, wie sie im Rahmen einer Ladephase erfolgt, Nebenwirkungen wie{" "}
  <b>Magen-Darm-Probleme</b> durchaus möglich. Symptome wie Übelkeit, Magenschmerzen
  oder Durchfall sind hierbei nicht auszuschließen und ließen sich ganz einfach durch
  eine geringdosierte Dauereinnahme vollständig vermeiden.
    </p>
    <p>
  <b>
    Eine geringdosierte Dauereinnahme ist einer hochdosierten Kur definitiv
    vorzuziehen und langfristig überlegen! Die Creatinmenge bei einer
    Dauereinnahme von etwa 0,05 g pro kg Körpergewicht und Tag gilt als sicher,
    einfach und effektiv. Bei einer 100 kg schweren Person wären das
    dementsprechend 5 g{" "}
    <AffiliateLink url={props.pageContext.frontmatter.product_creatin_url} mdxType="AffiliateLink">
      {props.pageContext.frontmatter.product_creatin_text}
    </AffiliateLink>{" "}
    pro Tag. Du kannst dir also merken, dass deine täglich eingenommene
    Creatinmenge zwischen 3 und 5 g liegen sollte.
  </b>
    </p>
    <h3>{`Du brauchst keine Einnahmepausen!`}</h3>
    <p>
  Vor allem im Zusammenhang mit einer nicht empfehlenswerten Creatin-Kur werden
  häufig Empfehlungen ausgesprochen, dass du Creatin z.B. nur 8 Wochen lang
  einnehmen solltest, gefolgt von einer mindestens 4-wöchigen Pause. Hierbei
  handelt es sich um pseudowissenschaftlichen Unfug; Creatin ist unabhängig von
  der Supplementation wesentlicher Bestandteil des Zellenergiestoffwechsels. Es
  handelt sich bei Creatin grundsätzlich um einen körpereigenen Stoff, welcher
  entsprechend auch endogen synthetisiert wird. Die Effektivität einer
  Creatinsupplementation ist bei einer Dauereinnahme optimal, was ebenfalls
  durch zahlreiche seriöse Studien belegt werden konnte.
    </p>
    <h2>{`Befolge dieses einfache und effektive Einnahmeschema...`}</h2>
    <p>
  Die Creatineinnahme sollte <b>ohne Unterbrechung</b> und <b>jeden Tag</b>{" "}
  erfolgen. Hierbei sind nur sehr geringe Mengen erforderlich, um die
  Creatinspeicher deines Körpers maximal gefüllt zu halten. Bei einer
  wissenschaftlich empfohlenen dauerhaften Einnahme solltest du{" "}
  <b>täglich 5 g Creatinmonohydrat</b> zu dir nehmen. Studien haben erwiesen,
  dass bei einer täglichen Einnahme von etwa 3 g Creatin täglich, die
  Creatinspeicher nach etwa vier Wochen maximal gefüllt sind.
    </p>
    <Image image={getImage(props.data.mdx.frontmatter.image_second)} imageAlt={props.data.mdx.frontmatter.image_second_alt} imageDesc={props.data.mdx.frontmatter.image_second_desc} imageAuthor={props.data.mdx.frontmatter.image_second_credit_text} imageLink={props.data.mdx.frontmatter.image_second_credit_link} mdxType="Image" />
    <p>
  Um die Einnahme zu optimieren, kannst du Creatin in einem Shake mit einem
  schnell verfügbaren Eiweißkonzentrat wie{" "}
  <NavLinkGlobal to="/de/blog/was-bedeutet-whey-protein" mdxType="NavLinkGlobal">
    Wheyprotein
  </NavLinkGlobal>{" "}
  zu dir nehmen. Abrunden kannst Du dies mit einer Quelle für{" "}
  <b>komplexe Kohlenhydrate</b> wie{" "}
  <AffiliateLink url={props.pageContext.frontmatter.product_maltodextrin_url} mdxType="AffiliateLink">
    {props.pageContext.frontmatter.product_maltodextrin_text}
  </AffiliateLink>{" "}
  oder{" "}
  <AffiliateLink url={props.pageContext.frontmatter.product_instant_oats_url} mdxType="AffiliateLink">
    {props.pageContext.frontmatter.product_instant_oats_text}
  </AffiliateLink>
  . Dies hat den zusätzlichen Vorteil, dass dein Körper Insulin ausschüttet, was
  die Aufnahme von Creatin in deine Muskelzellen erleichtert und gleichzeitig die
  Eiweißresorption verbessert. Eine veraltete Einnahmeempfehlung bestand darin Creatin
  Monohydrat mit kurzkettigen Kohlenhydraten wie Traubensaft, Traubenzucker bzw.
  Dextrose einzunehmen. Diese Kombination hat sich jedoch als nachteilig erwiesen,
  da die kurzkettigen Kohlenhydrate zu einem schnellen Abfall des Insulinspiegels
  führen. Bei diesem Ansatz verzichtest du also auf eine insulinbedingt bessere
  Creatinaufnahme in die Muskelzellen.
    </p>
    <p>
  Der Einnahmezeitpunkt ist sekundär, dennoch gibt es erschreckend viele Seiten
  im Internet, die das wissenschaftlich überholte Gerücht verbreiten, dass
  Creatin unbedingt nach dem Training einzunehmen ist. Begründet wird dies u.a.
  mit einer erhöhten trainingsbedingten Insulinsensitivität. Viele Studien haben
  jedoch eindeutig belegt, dass dies keinen direkten Effekt auf die
  Creatinresorption oder gar -wirkung hat. Dennoch kann es praktikabel sein,
  einen einzigen Shake anzurühren, welchen du nach dem Training zu dir nimmst
  und welcher entsprechend Creatin, neben anderen Komponenten wie{" "}
  <AffiliateLink url={props.pageContext.frontmatter.product_whey_url} mdxType="AffiliateLink">
    Wheyprotein
  </AffiliateLink>
  , enthält.
    </p>
    <p>
  <b>
    Du solltest bei der Creatineinnahme keinen Unterschied zwischen Trainings-
    und Pausentagen machen. Creatin sollte grundsätzlich jeden Tag eingenommen
    werden!
  </b>
    </p>
    <h3>{`Ist Creatin auf Dauer nicht zu teuer?`}</h3>
    <p>
  In der aktuellen Marktlage kosten Dich 5 g Creatin etwa 0,50 € am Tag. Es ist
  somit das wahrscheinlich effektivste und günstigste Nahrungsergänzungsmittel!
  Bei keinem anderen auf dem Markt befindlichen Supplement ist das Verhältnis
  von Preis und nachgewiesener Effektivität so stark ausgeprägt wie bei Creatin
  Monohydrat. Du solltest im Zweifelsfalle definitiv an anderer Stelle sparen!
    </p>
    <h3>{`Das musst du bei der Einnahme von Kaffee beachten!`}</h3>
    <p>
  Du wirst bei deiner Creatinrecherche sehr wahrscheinlich auf den alten Mythos
  gestoßen sein, dass Du unmittelbar vor und nach der Creatineinnahme keinen
  Kaffe trinken bzw. Coffeinpräparate einnehmen solltest. Begründet wird dies
  durch den dehydrierenden Effekt von Koffein, was der creatinbedingten
  Zellhydration entgegenwirken soll. Zahlreiche Studien konnten diesen Mythos
  mittlerweile jedoch eindeutig widerlegen. Du kannst also weiterhin getroßt
  deinen Lieblingskaffee trinken und gleichzeitig dein Creatinsupplement
  einnehmen!
    </p>
    <h2>{`Diese Creatinprodukte sind rausgeschmissenes Geld...`}</h2>
    <p>
  Neben dem Creatin-Monohydrat gibt es noch zahlreiche andere Creatinprodukte
  auf dem Markt, welche mit Zusätzen ergänzt wurden, die angeblich die
  Creatinwirkung bzw. -resorption optimieren sollen. Sobald Creatin jedoch in
  der Muskelzelle angekommen ist, spielt es absolut keine Rolle, welche
  Ergänzungen die Supplementhersteller beigemischt haben. Ebenso konnten
  zahlreiche Studien eindeutig belegen, dass Produkte wie <b>Kre-Alkalyn</b>,{" "}
  <b>Creatin AKG</b>, <b>Creatin HCL</b>, <b>Creatin-Matrix</b> und welche
  Modebegriffe es sonst noch gibt, keinen Zusatzeffekt haben. Außer natürlich
  den, dass sie deinen Geldbeutel unnötig strapazieren.
    </p>
    <p>
  Du bist skeptisch geworden? Dann picken wir uns einfach mal <b>Kre-Alkalyn</b>{" "}
  als Beispiel heraus. Bei diesem Präparat handelt es sich um Creatin, dem ein
  basisches Pulver beigemengt wurde. Dies soll laut Werbeversprechen zu einer
  erhöhten Stabilität des Creatins in Flüssigkeiten, wie z.B. der Magensäure
  führen, sodass dieses während der Verdauung vollständig resorbiert werden
  kann. Zahlreiche Studien konnten jedoch beweisen, dass die Resorption von
  Creatin verhältnismäßig schnell abläuft, sodass ein Zerfall von Creatin
  Monohydrat in Creatinin praktisch nicht auftritt bzw. zu vernachlässigen ist.{" "}
  Überteuerte Produkte wie beispielsweise <b>Kre-Alkalyn</b> haben folglich
  erwiesenermaßen keinerlei Vorteile gegenüber dem kostengünstigen{" "}
  <b>Creatin Monohydrat</b>.
    </p>
    <p>
  Die Einnahme eines qualitativ hochwertig synthetisierten Creatin Monohydrats,
  gerne mit dem Namenszusatz <b>Creapure</b>, ist absolut ausreichend und
  maximal kosteneffektiv. Lass dich also beim nächsten Durchblättern oder
  Durchscrollen eines Fitness-Magazins nicht an der Nase herumführen. Auch ist
  es unerheblich, ob du das{" "}
  <AffiliateLink url={props.pageContext.frontmatter.product_creatin_url} mdxType="AffiliateLink">
    {props.pageContext.frontmatter.product_creatin_text}
  </AffiliateLink>{" "}
  direkt in Pulverform oder in Form von Kapseln einnimmst. Letzteres ist jedoch
  erheblich teurer, hat aber keinen zusätzlichen Effekt.
    </p>
    <h2>{`Creatin wirkt auch bei dir!`}</h2>
    <p>
  In einigen Fällen berichten Athleten, dass sie keine Wirkung durch die
  Creatineinnahme feststellen können. Das kann unter anderem daran liegen, dass
  sie tatsächlich schon über die Nahrung genügend Creatin zu sich nehmen und
  dadurch die Speicher in den Muskelzellen gesättigt sind. Eine andere denkbare
  Ursache ist, dass die Einnahme unregelmäßig erfolgt und dadurch kein
  überdurchschnittlich großer Creatinspiegel aufgebaut werden kann.
    </p>
    <p>
  Grundsätzlich kann festgehalten werden, dass Creatin bei dir eine Wirkung hat,
  auch wenn du diese nicht unmittelbar wahrnimmst. Tatsächlich wird häufig
  beobachtet, dass Athleten mit der Creatinsupplementation aufhören, bevor sich
  deutlich messbare Effekte ausbilden können. Grund hierfür ist üblicherweise
  Ungeduld, die häufig mit einer unregelmäßigen Einnahme einhergeht.
  Selbstverständlich fällt die Wirkung von Creatin von Athlet zu Athlet
  unterschiedlich aus. Dies hängt vor allem davon ab, wie gesättigt deine
  Creatinspeicher schon vor der Supplementation waren. Doch auch wenn du nur
  eine Erhöhung der Creatinmenge in deiner Muskulatur von beispielsweise 10 %
  erreichst, so kann dies langfristig deine Regeneration verbessern,
  antikatabole Wirkungen entfalten und deine bisherigen Leistungsbarrieren
  brechen.
    </p>
    <h2>{`Das musst Du bei einer Verletzung beachten!`}</h2>
    <p>
  Solltest du dich verletzen und als Folge bestimmte Muskeln oder grundsätzlich
  nicht mehr trainieren können, so solltest du die Creatineinnahme unbedingt
  fortsetzen.{" "}
  <AffiliateLink url={props.pageContext.frontmatter.product_creatin_url} mdxType="AffiliateLink">
    {props.pageContext.frontmatter.product_creatin_text}
  </AffiliateLink>{" "}
  hat eine antikatabole Wirkung, die sich indirekt auch auf den Krafterhalt
  auswirkt. Als antikatabol wird eine muskelerhaltende bzw. Muskelabbau
  verhindernde Wirkung bezeichnet. Sportwissenschaftler haben zudem untersucht
  wie sich die Einnahme von Creatin auswirkt, wenn nach einer langen
  verletzungsbedingten Pause ein Rückgang der Kraftwerte zu verzeichnen ist.
  Diese konnten tatsächlich bei einer Creatinsupplementation deutlich schneller
  wiedererlangt werden. Um also deine mühselig aufgebaute Muskulatur und Kraft
  bis zur Wiederaufnahme des Trainings bestmöglich erhalten zu können, solltest
  du die Creatineinnahme beibehalten!
    </p>
    <p>
  Gleichzeitig hilft dir Creatin auch dabei, deine Muskulatur bezüglich des
  Querschnitts und der Kraftwerte, nach einer langen Verletzung schneller wieder
  aufzubauen und darüber hinaus zu entwickeln.{" "}
  <b>
    Gleiches gilt für Trainingspausen im Allgemeinen. Du solltest also
    unabhängig von der Pausenursache, deine Creatineinnahme wie gewohnt
    fortsetzen!
  </b>
    </p>
    <h2>{`Diese Wirkung hat Creatin auf dein Gehirn...`}</h2>
    <p>
  Creatin ist auch für die Energiebereitstellung bzw. die Resynthese von
  Adenosintriphosphat im Gehirn zuständig. Im Wachzustand wird das ATP abgebaut,
  bis alle 3 Phosphat-Moleküle abgetrennt sind. Adenosin bleibt schließlich
  übrig, was einen schlaffördernden Effekt hat. Dieser Umstand macht sich bei
  dir durch Schläfrigkeit bemerkbar. Durch eine Creatineinnahme stellst du
  deinem Gehirn mehr Energiereserven zur Verfügung, was zu einem gesteigerten
  Wachzustand und einer entsprechend erhöhten{" "}
  <Link url={props.pageContext.frontmatter.creatin_mental_performance_url} mdxType="Link">
    kognitiven Leistungsfähigkeit
  </Link>{" "}
  führt. Dieser Effekt hat zur Folge, dass du weniger anfällig für Stress und
  Anspannung bist, was u.a. eine Ursache von Schlafmangel sein kann. Dein
  rationales Denkvermögen ist auf einem messbar höherem Niveau, logische
  Herausforderungen kannst du folglich besser meistern. Im Hinblick auf
  Schlafmangel, konnten Forschergruppen auch eine Verbesserung der
  Körperkoordination, sowie einen erhöhten Testosteronspiegel im Vergleich zur
  Kontrollgruppe nachweisen.
    </p>
    <h2>{`Diese Punkte musst du dir merken!`}</h2>
    <p>
  Du hast diesen Artikel gelesen, weil du etwas über <b>Creatin</b> erfahren
  wolltest. Entweder hast du den Begriff häufig gehört und bist neugierig
  geworden, oder aber du hast dich gefragt, ob dieses Nahrungsergänzungsmittel
  etwas für dich ist. Doch was sind die wichtigsten Aspekte, die du mitnehmen
  solltest? Fassen wir einfach mal das Wichtigste zusammen:
    </p>
    <ul>
      <li parentName="ul">
        <AffiliateLink url={props.pageContext.frontmatter.product_creatin_url} mdxType="AffiliateLink">
  {props.pageContext.frontmatter.product_creatin_text}
        </AffiliateLink> ist ein hocheffektiver und hocheffizienter Energieträger deiner
Zellen.
      </li>
      <li parentName="ul">{`Creatin kann deine Kraftleistungen deutlich erhöhen.`}</li>
      <li parentName="ul">{`Creatin kann dir beim Muskelaufbau und beim Überwinden von Plateaus helfen.`}</li>
      <li parentName="ul">{`Creatin kann deine kognitive Leistungsfähigkeit steigern.`}</li>
      <li parentName="ul">{`Dein Körper kann Creatin selbst synthetisieren, aber diese Menge reicht für Sportler in der Regel nicht aus!`}</li>
      <li parentName="ul">{`Um einen Creatin-Mangel vorzubeugen, solltest du eine dauerhafte Creatineinnahme in Betracht ziehen.`}</li>
      <li parentName="ul">{`Vor allem als Veganer und Vegetarier solltest du ernsthaft über eine dauerhafte Creatineinnahme nachdenken!`}</li>
      <li parentName="ul">{`Nimm jeden Tag 5 g Creatin Monohydrat zu dir und nimm Abstand von Creatin-Kuren!`}</li>
    </ul>
    <Disclosure mdxType="Disclosure" />
    <AppPromotion mdxType="AppPromotion" />
    <BlogOverview mdxType="BlogOverview" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      